export default {
    get_integers: (n) => {
        n = n.toString();
        if(n.includes('.'))
            return (n.split(".")[0]).length;
        else
            return n.length;
    },
    get_decimals: (n) => {
        n = n.toString();
        if(n.includes('.'))
            return (n.split(".")[1]).length;
        else
            return 0;
    },
    common_number: (integer_digits = 6, decimal_digits = 3) => {
        //Recursive function (adds (\d{1,number-3}(\.)?) ) for every 3 integer spaces to the regex
        //if(integer_digits>0 && integer_digits <= 3)

        let exp = '^';

        let int_function = function (number) {
            return (number > 3) ? '(((' + int_function(number - 3) + '(\\,)?)\\d{3})|(\\d{1,3}))' : '\\d{1,' + number + '}';
        };

        exp += int_function(integer_digits);

        if (decimal_digits > 0)
            exp += '(\\.\\d{1' + ((decimal_digits > 1) ? ',' + decimal_digits : '') + '})?';

        exp += '$';

        return new RegExp(exp);
    },
    name: () => {
        return new RegExp('[0-9a-zA-Z一-龥+\\/.\\\\!\\-?\\\'_\\],\\[$%^*()"|—，。、~@#￥…&（） ]+');

    },
    code: () => {
        return new RegExp('^[0-9a-zA-Z一-龥_\\-]+$');
    },
    phone: () => {
        return new RegExp('(\\+)?(\d{2,}|\s|\-)+');
        //return new RegExp('^(\\+\(\d{2,3}\))?[\d\s\-]{8,12}$');
    },
    email: () => {
        return new RegExp('([a-z一-龥][a-z0-9一-龥_\\-+.]+[a-z0-9一-龥])@[a-z一-龥][a-z0-9一-龥_\\-]{3,}(\\.[a-z一-龥][a-z0-9一-龥_\\-]{2,})', 'i');
    },
    url: () => {
        return new RegExp('^(http://)?([0-9a-zA-Z]{2,}\.)?[0-9a-zA-Z\\-_]{2,}\.[0-9a-zA-Z]{2,}(\/[0-9a-zA-Z-_]+)*$');
    },
    empty_or_min_max: (min = 3, max = 255) => {
        return new RegExp('^(?:.{' + min + ',' + max + '}|)$');
    },
    length_min: (min = 3) => {
        return new RegExp('^.{' + min +',}$');
    },
    length_max: (max = 255) => {
        return new RegExp('^.{' + max + '}$');
    },
    non_permmited_characters: (others = '') => {
        return new RegExp('^[^|&{}/\:*?"<>' + others + ']$');
    },
}
<template>
    <v-container grid-list-sm>
        <v-layout justify-center row fill-height wrap>
            <v-flex sm12 md6 pa-3 v-if="product.album && product.album.files.length">
                    <v-carousel>
                        <v-carousel-item v-for="image in product.album.files"
                                 :key="image.id"
                                 :src="`${$store.state.backend_url.replace('/api', '') + image.url}`"
                        ></v-carousel-item>
                    </v-carousel>

                    <h2>{{product.name}}</h2>
                    <h5>{{product.erp_item_number}}</h5>
                    <p>{{product.description}}</p>
            </v-flex>
            <v-flex sm12 md6 pa-3>
                <div v-if="full_access">
                    <h2 class="display-1">
                        Factory Info
                    </h2>
                    <h3 class="headline mb-5">
                        {{ factory.name }}
                    </h3>
                    <p class="mb-5">
                        {{ factory.address }}
                    </p>

                    <h3 class="headline mb-5">
                        Contacts
                    </h3>

                    <ol>
                        <li v-for="user in factory.users">
                            {{ user.first_name }} - {{ user.email }}
                        </li>
                    </ol>
                </div>
                <div>
                    <h2 class="display-1 mb-5">
                        Product Info
                    </h2>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td>
                                    <strong>
                                        Item Num WS
                                    </strong>
                                </td>
                                <td>
                                    {{ product.erp_item_number }}
                                </td>
                            </tr>
                            <tr v-if="full_access">
                                <td>
                                    <strong>
                                        Item Num
                                    </strong>
                                </td>
                                <td>
                                    {{ product.item_number }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>
                                        MOQ
                                    </strong>
                                </td>
                                <td>
                                    {{ product.qty_moq }}
                                </td>
                            </tr>
                            <tr v-if="full_access">
                                <td>
                                    <strong>
                                        Factory FOB #{{ factory.product.id }}
                                    </strong>
                                </td>
                                <td>
                                    ${{ factory.product.fob_price.toFixed(2) }}
                                </td>
                            </tr>

                            <tr v-if="!is_sourcer">
                                <td>
                                    <strong>
                                        FOB # {{ product.id }}
                                    </strong>
                                </td>
                                <td>
                                    ${{ product.fob_price.toFixed(2) }}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <strong>
                                        Carton Measures
                                    </strong>
                                </td>
                                <td>
                                    {{ product.carton_measures_depth }} * {{ product.carton_measures_height }} * {{ product.carton_measures_width }}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <strong>
                                        Gross Weight
                                    </strong>
                                </td>
                                <td>
                                    {{ product.gross_w }}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <strong>
                                        Net Weight
                                    </strong>
                                </td>
                                <td>
                                    {{ product.net_weight }}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <strong>
                                        QTY 20
                                    </strong>
                                </td>
                                <td>
                                    {{ product.qty_20 }}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <strong>
                                        QTY 40

                                    </strong>
                                </td>
                                <td>
                                    {{ product.qty_40 }}

                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <strong>
                                        QTY 40hq

                                    </strong>
                                </td>
                                <td>
                                    {{ product.qty_40hq }}

                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <strong>
                                        Packing
                                    </strong>
                                </td>
                                <td>
                                    {{ product.packaging }}
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <strong>
                                        Unit
                                    </strong>
                                </td>
                                <td>
                                    {{ product.unit }}
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <v-btn outlined color="blue-grey darken-4"
                           block
                           @click="add_to_cart(product)"
                           v-if="user"
                    >
                        <v-icon>
                            mdi-plus
                        </v-icon> Cart
                    </v-btn>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import cRegex from '../../utils/CommonRegex.js'

    export default {
        name: "Product",
        props: {
            user: Object
        },
        data: () => ({
            full_access:false,
            factory: {product:{}},
            product: [],
            valid_cart_form: false,
            is_sourcer: false,
            cart_info: {
                erp_item_number: undefined,
                product: 0,

                qty: 0,
                fob_price: 0,

                target_price: 0,
                remarks: '',
                packing: '',
                display: '',
                artwork: '',
                master_carton_qty: 0,
                inner_carton_qty: 0,
                battery_included: '',
            },
        }),
        created() {
            console.log(this.user);
            this.erp_item_number = this.$route.params.erp_item_number;
            this.getProductData();
        },
        computed: {
            rules_cart_qty(){
              return [
                  v => !!v || 'Required field',
                  v => Number.isInteger(v) || "Needs to have a valid number format",
                  v => v > 0 || 'At least one item needed to make an invoice'
              ];
            },
            rules_cart_target_price (){
                return [
                    v => !!v || 'Required field',
                    v => cRegex.common_number().test(v) || 'Number format is not correct (xx,xxx,xxx.fff or xxxxxxxx.fff)',
                    v => cRegex.get_integers(v) <= 99999999 || 'The price should not exceed 99 million in value',
                    v => cRegex.get_decimals(v) <= 3 || `The price should not contain more than 3 fractional values`,
                    v => v > 0 || 'At least one item needed to make an invoice'
                ];
            },
            rules_cart_remarks (){
                let max_length = 400;
                let min_length = 8;
                return [
                    v => cRegex.empty_or_min_max(min_length, max_length).test(v || '')
                        || 'This text must contain between ' + min_length + ' and ' + max_length + 'characters'
                ];
            },
            rules_cart_packing (){

            },
            rules_cart_display (){

            },
            rules_cart_artwork (){

            },
            rules_cart_master_carton_qty (){
                return [
                    v => !!v || 'Required field',
                    v => Number.isInteger(v) || "Needs to have a valid number format",
                    v => v > 0 || 'At least one item needed to make an invoice'
                ];
            },
            rules_cart_inner_carton_qty (){
                return [
                    v => !!v || 'Required field',
                    v => Number.isInteger(v) || "Needs to have a valid number format",
                    v => v > 0 || 'At least one item needed to make an invoice'
                ];
            },
            rules_cart_battery_included (){

            }
        },
        methods:{
            add_to_cart (product) {
                let default_info = {
                    artwork: 'OEM Packing',
                    battery_included: 'NO',
                    display: 'PDQ',
                    fob_price: product.fob_price,
                    inner_carton_qty: 0,
                    master_carton_qty: 0,
                    packing: 'Color Box',
                    product: product.id,
                    qty: 1000,
                    remarks: 'N/A',
                    target_price: product.fob_price,
                };

                this.$hpost('/store/cart', default_info)
                    .then((response)=>{
                        console.log(response);
                        this.$notify({
                            title: 'Success:',
                            text: 'Successfully added to cart.',
                            group: 'notifications',
                            position: 'top right'
                        });
                        this.$emit('get-cart-products');
                    });
            },
            getProductData() {
                this.$http.get('/store/products', { params: { erp_item_number: this.erp_item_number }})
                    .then( product_data => {
                        console.log("PROD DAT");
                        console.log(product_data);
                        this.product = product_data.data;
                        // Setting for quotation
                        this.cart_info.product = this.product.id;
                        this.cart_info.qty = 0;
                        this.cart_info.fob_price = this.product.fob_price;
                        this.cart_info.target_price = this.product.fob_price;
                        let sa = this.user.groups.find(o => o.id === 1);
                        let admin = this.user.groups.find(o => o.id === 2);
                        let sourcer = this.user.groups.find(o => o.id === 3);
                        let sourcer_manager = this.user.groups.find(o => o.id === 17);
                        let logistics = this.user.groups.find(o => o.id === 15);
                        if(sa || admin || sourcer || logistics || sourcer_manager)
                        {
                            // load factory details
                            this.full_access = true;
                            this.load_factory_data(product_data.data);
                        }
                        if(sourcer || sourcer_manager)
                        {
                            this.is_sourcer = true;
                        }

                    });
            },
            load_factory_data(product)
            {
                this.$hget(
                    this.$http.defaults.baseURL + '/store/factory-and-product/' + product.factory_id+'' +
                    '/'+product.id+'/' ).then((response) => {
                        if(typeof response.data !== 'undefined')
                        {
                            response.data.factory.product = response.data.product;
                            this.factory = response.data.factory
                        }
                    });
            },
            postCart () {
                if (!this.$refs.cart_form.validate())
                    return false;

                // TODO: Check for errors before posting
                this.$http.post('/store/cart', this.cart_info)
                    .then((response)=>{
                        console.log(response);
                        this.$emit('get-cart-products');
                    })
                    .catch((error)=>{
                        console.log(error);
                    });
            },
            addToCart () {
                this.postCart();
            },
            addAndGoToCard(){
                if (this.postCart())
                    this.$emit('navigate-to-checkout');

            }


        }

    }
</script>
